export interface ExchangeCodeRequest {
  code: string,
  clientId: string,
  redirectUrl: string,
};

export interface AuthCodeExchangeResponse {
  id_token: string,
  access_token: string,
  refresh_token: string,
};

export enum AuthState {
  INIT,
  AUTHENTICATED,
  UNAUTHENTICATED,
};