import React, { useState, useEffect, useRef } from 'react';
import NavBar from '../../components/navigation/NavBar';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
	CognitoUserPool,
	CognitoUser,
  CognitoUserSession,
  ICognitoUserPoolData,
  AuthenticationDetails,
  IAuthenticationDetailsData,
  IAuthenticationCallback
} from 'amazon-cognito-identity-js';
import { setAuthState, updateUserFromCachedSession } from '../../reducers/SessionSlice';
import { useNavigate } from 'react-router-dom';
import { AuthState } from '../../types/authTypes';
import { getConfigAsync } from '../../reducers/ConfigSlice';
import { LoadState } from '../../types';

export default function Login() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { sessionState, configState } = useAppSelector((state) => state);

  const userPoolRef = useRef<CognitoUserPool>();

  const [username, setUsername] = useState('');
  const [pw, setPw] = useState('');
  const [loginError, setLoginError] = useState('');

  useEffect(() => {
    if (configState.loadState === LoadState.INIT) {
        dispatch(getConfigAsync());
    } else if (configState.loadState === LoadState.LOADED) {
        const poolData: ICognitoUserPoolData = {
            UserPoolId: configState.config?.userPoolId || '',
            ClientId: configState.config?.userPoolClientId || '',
        }

        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
          poolData.endpoint = process.env.REACT_APP_COGNITO_ENDPOINT
        }
        userPoolRef.current = new CognitoUserPool(poolData);

        
        loadSession();   
    }
}, [configState.loadState, configState.config?.userPoolId, configState.config?.userPoolClientId]);  //eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (sessionState.authState === AuthState.AUTHENTICATED) {
      navigate("/");
    }
  }, [sessionState.authState, navigate])

  const loadSession = () => {
    if (userPoolRef.current === undefined) {
        console.error("UserPool undefined");
        return;
    }

    const user = userPoolRef.current.getCurrentUser();
    
    if ( user !== null) {
        user.getSession((err: Error | null, session: CognitoUserSession) => {
            if (err === null) {
                dispatch(updateUserFromCachedSession(session));
            } else {
                console.log("Error getting session", err);
                user.signOut();
                dispatch(setAuthState(AuthState.UNAUTHENTICATED));
            }
        });
        return;
    } 
    dispatch(setAuthState(AuthState.UNAUTHENTICATED));    
  }

  

  const handleLogin = () => {
    // TODO: figure out how to not do this multiple places
    const poolData: ICognitoUserPoolData = {
      UserPoolId: configState.config?.userPoolId || '',
      ClientId: configState.config?.userPoolClientId || '',
    }
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      poolData.endpoint = process.env.REACT_APP_COGNITO_ENDPOINT
    }
    const userPool = new CognitoUserPool(poolData);
       // userPoolRef.current.signUp(
    //   'manatua', 
    //   'manatua', 
    //   [
    //     new CognitoUserAttribute({Name: 'given_name', Value: 'Monte'}), 
    //     new CognitoUserAttribute({Name: 'family_name', Value: 'Misa'})
    //   ],
    //    [],
    //    (err, result) => {
    //     if (err) {
    //       console.log(err);
    //       return;
    //     }
    //     console.log(result);
    //     console.log(result?.user)
    //    });

    const user = new CognitoUser({Username: username, Pool: userPool});
    console.log(user);
    const authDetails:IAuthenticationDetailsData = {
      Username: username,
      Password: pw,
    }

    

    const authCallback:IAuthenticationCallback = {
      onSuccess: (session, _confirmationNecessary) => {
        dispatch(updateUserFromCachedSession(session));
      },
      onFailure: (err) => {
        console.log(err);
        setLoginError(err.message);
      }
    }
    // user.confirmRegistration('104973', false, (err, result) => {
    //   if (err) console.log(err);
    //   else {
    //     console.log(result);
    //   }
    // });
    user.setAuthenticationFlowType('USER_PASSWORD_AUTH');
    user.authenticateUser(new AuthenticationDetails(authDetails), authCallback);
  }

  return(
      <div>
        <NavBar />
        <input value={username} onChange={(e) => setUsername(e.currentTarget.value)} />
        <input type='password' value={pw} onChange={(e) => setPw(e.currentTarget.value)}/>
        <button className="button__login" onClick={handleLogin}>
          Log In
        </button>
        {loginError && <p>{loginError}</p>}
      </div>
    
  );

}