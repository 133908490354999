import { combineReducers } from 'redux';

import usersReducer from './UsersSlice';
import sessionReducer from './SessionSlice';
import configReducer from './ConfigSlice';


const rootReducer = combineReducers({
  usersState: usersReducer,
  sessionState: sessionReducer,
  configState: configReducer,
});

export default rootReducer;