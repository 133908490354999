import React from 'react';
import { ColorRing } from 'react-loader-spinner';

interface Props {
  isVisible: boolean,
}
export default function LoadingIndicator(props: Props) {
  return(
    <ColorRing
      visible={props.isVisible }
      height="80"
      width="80"
      ariaLabel="blocks-loading"
      wrapperStyle={{}}
      wrapperClass="blocks-wrapper"
      colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
    />
  )
}