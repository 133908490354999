import { AxiosRequestConfig } from "axios";
import { RequestHandler } from "./RequestHandler";
import UserService from './UserService';
import SessionService from './SessionService';
import ConfigService from './ConfigService';


export class ManatuaApi {
    userService: UserService;
    sessionService: SessionService;
    configService: ConfigService;
    

    // Can't use RootState type due to circular reference.
    constructor(state: any, baseURL: string) {
        const defaultConfig: AxiosRequestConfig = {
            headers: {
                Authorization: `Bearer ${state.sessionState.user?.idToken}`,
                'Content-Type': 'application/json',
            },
            baseURL,
        }

        const requestHandler = new RequestHandler(baseURL, defaultConfig);
        this.userService = new UserService(requestHandler);
        this.configService = new ConfigService(requestHandler);
        this.sessionService = new SessionService(requestHandler);
    }
}

