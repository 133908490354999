// import { AxiosRequestConfig } from "axios";
// import { User } from "../types";
import { $Service } from "./Service";


export default class UserService extends $Service {
    getUsers() {
        return this.client.get("/users", {});
    }
}

