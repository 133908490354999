import React from 'react';
import { NavBarBrand } from './NavBarBrand';
import { NavBarTabs } from './NavBarTabs';
import { NavBarButtons } from './NavBarButtons';

export default function NavBar() {
  return (
    <div className="nav-bar__container">
      <nav className="nav-bar">
        <NavBarBrand />
        <NavBarTabs />
        <NavBarButtons />
      </nav>
    </div>
  );
  
}