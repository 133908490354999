import  React, {useEffect}  from 'react';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { LoadState } from '../../types';
import { getUsersAsync } from '../../reducers/UsersSlice';

export default function Home() {
  const dispatch = useAppDispatch();

  const {usersState, sessionState} = useAppSelector(state=> state);
  useEffect(() => {
    if (usersState.loadState === LoadState.INIT) {
      dispatch(getUsersAsync());
    }
  }, []) //eslint-disable-line react-hooks/exhaustive-deps
  const renderUsers = () => {
    return (
      <div>
        <p>Loaded users</p>
        {usersState.users.map((user) => {
      return(<p>{user.name}</p>)})}

      </div>
    );

  }
  return (
  <div>
    <h1>Home page</h1>
    Hello {sessionState.user.username}
    {usersState.loadState === LoadState.LOADED && renderUsers()}

  </div>
  );
}