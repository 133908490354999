import React from 'react';
import './App.css';
import { Provider } from 'react-redux';
import store from './store';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/home';
import Callback from './pages/Callback/Callback';
import Profile from './pages/Profile/profile';
import Login from './pages/Login';
import AppContainer from './components/AppContainer/AppContainer';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Provider store={store}>
          <Routes>
            <Route path="/" element={<AppContainer />} >
              <Route index element={<Home />} />
              <Route path="/callback" element={<Callback />} />
              <Route path="/profile" element={<Profile />} />
              {/* //Make in dev only */}
            </Route>
            <Route path="/login" element={<Login />} />
          </Routes>
        </Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
