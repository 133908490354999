import { ManatuaApi } from './api/ManatuaApi';
import { RootState } from './store/index';

export interface ThunkMiddlewareExtraType {
  api: (state: any) => ManatuaApi
}

export interface ThunkApiType {
  extra: ThunkMiddlewareExtraType,
  state: RootState,
}


export enum LoadState {
  INIT,
  LOADING,
  LOADED,
  ERROR,
};



export interface User {
  name?: string,
  accessToken?: string
  idToken?: string,
  familyName?: string,
  givenName?: string,
  username?: string,
  email?: string,
  // role?: Role, 
  enabled?: boolean,
  sub?: string,
};