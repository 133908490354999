import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApiUser } from '../apiTypes';
import { LoadState, ThunkApiType, User } from '../types';


interface UsersState {
    loadState: LoadState,
    users: User[],
    inviteUserLoadState: LoadState,
};

const initialState: UsersState  = {
    loadState: LoadState.INIT,
    users: [],
    inviteUserLoadState: LoadState.INIT,
};


export const getUsersAsync = createAsyncThunk<ApiUser[], void, ThunkApiType>(
    'usersState/getUsers',
    async (_, thunkApi) => {
      const state = thunkApi.getState();
      const response = await thunkApi.extra.api(state).userService.getUsers();
      return response;
    }
);


export const usersSlice = createSlice(
  {
    name: 'UsersState',
    initialState,
    reducers: {
        setInviteUserLoadState: (state, action) => {
        },
    },
    extraReducers: (builder) => {
      builder
      .addCase(getUsersAsync.pending, (state) => {
        state.loadState = LoadState.LOADING;
      })
      .addCase(getUsersAsync.fulfilled, (state, action) => {
        state.loadState = LoadState.LOADED;
        state.users = action.payload;
      })
      .addCase(getUsersAsync.rejected, (state, action) => {
        state.loadState = LoadState.ERROR;
      })
    }
  }
)

export default usersSlice.reducer;

