import React from 'react';

export default function Profile() {

  return(
    <div>
      <p>Profile</p>
    </div>
  )

}