import React from 'react';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { setAuthState } from '../../reducers/SessionSlice';
import { AuthState } from '../../types/authTypes';

export const NavBarButtons = () => {
  const dispatch = useAppDispatch();
  const { sessionState} = useAppSelector(state=> state);

  const handleLogout = () => {
    dispatch(setAuthState(AuthState.UNAUTHENTICATED));
  }
  return (
    <div className="nav-bar__buttons">
      {sessionState.authState === AuthState.UNAUTHENTICATED && (
        <>
          {/* <SignupButton /> */}
          <p>Login</p>
        </>
      )}
      {sessionState.authState === AuthState.AUTHENTICATED && (
        <>
          <button onClick={handleLogout}>Log out</button>
        </>
      )}
    </div>
  );
};