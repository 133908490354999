import React, { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { LoadState } from '../../types';

import NavBar from '../navigation/NavBar';

import {
	CognitoUserPool,
	CognitoUser,
    CognitoUserSession,
    CognitoIdToken,
    CognitoAccessToken,
    CognitoRefreshToken,
    ICognitoUserPoolData,
} from 'amazon-cognito-identity-js';
import { Outlet, useLocation } from 'react-router-dom';
import { ExchangeCodeRequest, AuthState } from '../../types/authTypes';
import { setAuthState, updateUserFromCachedSession, exchangeCodeForTokenAsync } from '../../reducers/SessionSlice';
import { getConfigAsync } from '../../reducers/ConfigSlice';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';

function AppContainer() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const authCode = new URLSearchParams(location.search).get('code');

  const userPoolRef = useRef<CognitoUserPool>();
  const userRef = useRef<CognitoUser>();
  const { sessionState, configState } = useAppSelector((state) => state);

  useEffect(() => {
    if (configState.loadState === LoadState.INIT) {
        dispatch(getConfigAsync());
    } else if (configState.loadState === LoadState.LOADED) {
        const poolData: ICognitoUserPoolData = {
            UserPoolId: configState.config?.userPoolId || '',
            ClientId: configState.config?.userPoolClientId || '',
        }

        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
          poolData.endpoint = process.env.REACT_APP_COGNITO_ENDPOINT
        }
        userPoolRef.current = new CognitoUserPool(poolData);

        
        loadSession();   
    }
}, [configState.loadState, configState.config?.userPoolId, configState.config?.userPoolClientId]);  //eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   const poolData: ICognitoUserPoolData = {
  //     UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || '',
  //     ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || '',
  //   }
  //   if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  //     poolData.endpoint = process.env.REACT_APP_COGNITO_ENDPOINT
  //   }
  //   userPoolRef.current = new CognitoUserPool(poolData);
  //   loadSession();
 
  // }, []);  //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sessionState.loadState === LoadState.LOADED) {
        // This happens after the sign in flow (vs using existing session flow)
        const IdToken = new CognitoIdToken({IdToken: sessionState.user.idToken || ''});
        const AccessToken = new CognitoAccessToken({AccessToken: sessionState.user.accessToken || ''});
        const RefreshToken = new CognitoRefreshToken({RefreshToken: ''})
        const session = new CognitoUserSession({IdToken, AccessToken, RefreshToken});
        
        userRef.current = new CognitoUser({Username: sessionState.user.username || '', Pool: userPoolRef.current!});
        userRef.current.setSignInUserSession(session);
    }
}, [sessionState.loadState, sessionState.user]);


useEffect(() => {
  if (sessionState.authState === AuthState.UNAUTHENTICATED) {
      console.log('user unauth, redirecting')
      if (userRef.current) {
          userRef.current.signOut(() => {
              window.location.href = configState.config!.userLoginUrl!;
          })
      } else {
          window.location.href = configState.config!.userLoginUrl!;
        }
  }
}, [sessionState.authState, configState.config]); 

  const loadSession = () => {
    if (userPoolRef.current === undefined) {
        console.error("UserPool undefined");
        return;
    }

    const user = userPoolRef.current.getCurrentUser();
    
    if ( user !== null) {
        user.getSession((err: Error | null, session: CognitoUserSession) => {
            if (err === null) {
                dispatch(updateUserFromCachedSession(session));
            } else {
                console.log("Error getting session", err);
                user.signOut();
                dispatch(setAuthState(AuthState.UNAUTHENTICATED));
            }
        });
        return;
    } 

    if (authCode) {
        // If user is null and we have auth code then we likely arrived here after being redirected
        // from the sign in hosted UI. Need to exchange code for tokens.
        console.debug("user is null and we have auth code, need to exchange", authCode)
        const exchangeRequest: ExchangeCodeRequest = {
            clientId: configState.config?.userPoolClientId || '',
            code: authCode,
            redirectUrl: getRedirectUri(),
        }
        dispatch(exchangeCodeForTokenAsync(exchangeRequest))
    } else {
        console.log("No user and no auth code. Need to send them to sign in page")
        dispatch(setAuthState(AuthState.UNAUTHENTICATED));
    }
  }
  
  const getRedirectUri = () => {
    return window.location.origin ;
  }
  const showContent = () => 
        configState.loadState === LoadState.LOADED && sessionState.authState === AuthState.AUTHENTICATED
  return (
    <div className="page-layout">
      {showContent() && <NavBar /> }
      <LoadingIndicator isVisible={configState.loadState === LoadState.LOADING || sessionState.loadState === LoadState.LOADING} />

            { showContent() && <Outlet /> }
      {/* <PageFooter /> */}
    </div>
  );
}

export default AppContainer;
